import {useApp} from 'AppContext'
import {Block} from 'blocks/Block'
import {CMSImageblockBlock} from 'cms-types'
import {Image} from 'layout/Image'
import {Link} from 'layout/Link'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {Zoom} from 'layout/Zoom'
import {useMediaQuery} from 'util/mediaquery'
import {fromModule} from 'util/styler/Styler'
import css from './Imageblock.module.scss'

const styles = fromModule(css)

export const Imageblock: React.FC<CMSImageblockBlock> = block => {
	const {node} = useApp()
	const isIpadLand = useMediaQuery('(min-width: 1024px)')
	const {title, fullwidth, image, caption, link, width, align} = block

	if (!image?.src) return null

	const maxWidth =
		!fullwidth && width
			? {maxWidth: width}
			: image?.width <= 570
			? {maxWidth: image?.width}
			: null
	const isFullwidth =
		isIpadLand && fullwidth && node?.channel !== 'product_detail'

	return (
		<Block
			block={block}
			className={styles.imageblock
				.mod({fullwidth: isFullwidth})
				.mod(node?.channel)
				.mod(align)()}
		>
			<div className={styles.imageblock.wrapper()}>
				{title && (
					<div className={styles.imageblock.title()}>
						<Title.H5>{title}</Title.H5>
					</div>
				)}
				<div className={styles.imageblock.image()} style={maxWidth}>
					{link?.url ? (
						<Link to={link.url}>
							<Image
								{...image}
								layout={isFullwidth ? 'fill' : 'responsive'}
								sizes={
									isFullwidth ? '100vw' : '(max-width: 1200px) 100vw, 1500px'
								}
							/>
						</Link>
					) : (
						<Zoom image={image}>
							<Image
								{...image}
								layout={isFullwidth ? 'fill' : 'responsive'}
								sizes={
									isFullwidth ? '100vw' : '(max-width: 1200px) 100vw, 1500px'
								}
							/>
						</Zoom>
					)}
					{image?.meta?.copyright && (
						<p className={styles.imageblock.image.copyright()}>
							{'© ' + image.meta.copyright}
						</p>
					)}
				</div>
				{caption && (
					<Wysiwyg className={styles.imageblock.caption()}>{caption}</Wysiwyg>
				)}
			</div>
		</Block>
	)
}
