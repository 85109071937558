import {Block} from 'blocks/Block'
import {CMSVideoBlock} from 'cms-types'
import {Icon} from 'layout/Icon'
import ReactPlayer from 'react-player'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Video.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useState} from 'react'

const styles = fromModule(css)

export const Video: React.FC<CMSVideoBlock> = block => {
	const [isPlaying, setPlaying] = useState<boolean>(false)
	const {url, image, text} = block
	if (!url) return null

	return (
		<Block
			block={block}
			className={styles.video()}
			onClick={() => setPlaying(!isPlaying)}
		>
			<div className={styles.video.wrapper()}>
				<ReactPlayer
					url={url}
					playing={isPlaying}
					width={'100%'}
					height={'100%'}
					controls={true}
					style={{
						position: 'absolute',
						left: 0,
						right: 0,
						zIndex: 1
					}}
					light={image?.src || true}
					playIcon={
						<span className={styles.video.wrapper.play()}>
							<Icon icon="play" />
						</span>
					}
				/>
			</div>
			{text && <Wysiwyg className={styles.video.text()}>{text}</Wysiwyg>}
		</Block>
	)
}
