import { CMSFeaturedinspirationBlock, CMSLink, CMSWysiwyg } from 'cms-types'
import { Carousel, useCarousel } from '@codeurs/carousel'
import { CarouselNext, CarouselPrev, CarouselScroll } from 'layout/carousel/CarouselControls'
import {
	InspirationCard,
	InspirationCardType
} from 'layout/cards/InspirationCard'

import { Block } from 'blocks/Block'
import { CMSFeaturedinspirationBlockExtra } from './featuredinspiration.data'
import { LinkStyled } from 'layout/LinkStyled'
import { Title } from 'layout/Title'
import { Wysiwyg } from 'layout/Wysiwyg'
import css from './FeaturedInspiration.module.scss'
import { fromModule } from 'util/styler/Styler'
import { useEffect } from 'react'

const styles = fromModule(css)

type FeaturedContentType = {
	title: string
	text?: CMSWysiwyg
	link?: CMSLink
}

export const FeaturedInspiration: React.FC<
	CMSFeaturedinspirationBlock & CMSFeaturedinspirationBlockExtra
> = block => {
	const {title, text, link, cards} = block
	if (!title && !text && !cards?.length) return null

	return (
		<Block block={block} className={styles.featuredinspiration()}>
			<div className={styles.featuredinspiration.row()}>
				<FeaturedContent title={title} text={text} link={link} />
				<FeaturedOverview cards={cards} />
			</div>
		</Block>
	)
}

const FeaturedContent: React.FC<FeaturedContentType> = ({
	title,
	text,
	link
}) => {
	if (!title && !text && !link) return null

	return (
		<div className={styles.content()}>
			{title && <Title.H2 className={styles.content.title()}>{title}</Title.H2>}
			{text && <Wysiwyg className={styles.content.text()}>{text}</Wysiwyg>}
			{link?.url && (
				<LinkStyled
					to={link.url}
					iconafter="chevron_right"
					className={styles.content.link()}
				>
					{link.description || link.title}
				</LinkStyled>
			)}
		</div>
	)
}

const FeaturedOverview: React.FC<{
	cards: Array<InspirationCardType>
}> = ({cards}) => {
	const carousel = useCarousel()

	useEffect(() => {
		if (!cards?.length) return null
	}, [cards.length])

	return (
		<div className={styles.overview()}>
			<div className={styles.overview.carousel()}>
				<CarouselPrev carousel={carousel} mod="absolute" />
				<div className={styles.overview.carousel.items()}>
					<Carousel {...carousel} full>
						{cards.map((card, index) => (
							<div
								key={index}
								className={styles.overview.carousel.items.item.is(
									carousel.getPosition(index)
								)()}
							>
								<InspirationCard
									{...card}
									mod="featured"
									tabIndex={!carousel.isActive(index) ? -1 : undefined}
									ariaHidden={!carousel.isActive(index)}
								/>
							</div>
						))}
					</Carousel>
				</div>
				<CarouselNext carousel={carousel} mod="absolute" />
				<CarouselScroll carousel={carousel} mod="dark" />
			</div>
		</div>
	)
}
