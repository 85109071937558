import {CMSImage, CMSLink, CMSWysiwyg} from 'cms-types'

import {Button} from 'layout/Button'
import {Image} from 'layout/Image'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Cta.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useMediaQuery} from 'util/mediaquery'

const styles = fromModule(css)

type bgcolorType = 'purple' | 'navy_blue' | 'brown'

export type CtaType = {
	title: string
	text?: CMSWysiwyg
	button?: CMSLink
	bgcolor?: bgcolorType
	image?: CMSImage
	span?: '1' | '2' | '3'
}

export const Cta: React.FC<CtaType> = ({
	title,
	text,
	button,
	bgcolor,
	image,
	span
}) => {
	const isIpadLand = useMediaQuery('(min-width: 1024px)')
	if (!title && !text && !button?.url) return null
	const modSpan = span && `span${span}`

	return (
		<div
			className={styles.cta
				.mod(bgcolor)
				.mod({image: image?.src})
				.mod(modSpan)()}
			style={
				bgcolor === 'purple' && isIpadLand
					? {backgroundImage: `url(${image?.src})`}
					: {}
			}
		>
			{(bgcolor === 'navy_blue' || bgcolor === 'brown') && (
				<CtaMask bgcolor={bgcolor} image={image} />
			)}
			{!bgcolor && !span && <CtaImage image={image} />}
			<CtaContent title={title} text={text} button={button} bgcolor={bgcolor} />
		</div>
	)
}

export const CtaImage: React.FC<{
	image: CMSImage
}> = ({image}) => {
	if (!image?.src) return null

	return (
		<div className={styles.image()}>
			<Image {...image} layout="responsive" sizes="310px" />
		</div>
	)
}

export const CtaContent: React.FC<{
	title: string
	text: CMSWysiwyg
	button: CMSLink
	bgcolor?: bgcolorType
}> = ({title, text, button, bgcolor}) => {
	return (
		<div className={styles.content()}>
			{title && <h2 className={styles.content.title()}>{title}</h2>}
			{text && <Wysiwyg className={styles.content.text()}>{text}</Wysiwyg>}
			{button?.url && (
				<Button
					to={button.url}
					iconafter="chevron_right"
					className={styles.content.button()}
					mod={bgcolor === 'navy_blue' && 'purple'}
				>
					{button.description || button.title}
				</Button>
			)}
		</div>
	)
}

export const CtaMask: React.FC<{
	image: CMSImage
	bgcolor?: bgcolorType
}> = ({image, bgcolor}) => {
	if (!image?.src) return null

	return (
		<div className={styles.mask()}>
			<span
				className={styles.mask.bg()}
				style={{backgroundImage: `url('${image?.src}')`}}
			/>
			<svg
				className={styles.mask.svg()}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 960 405"
			>
				<path
					fill={bgcolor === 'brown' ? '#32200b' : '#0b0f32'}
					d="M0,0V405H703.38l51.17-47.85L638.69,330.4a12.63,12.63,0,0,1-5.78-21.53L881.16,76.71a25.29,25.29,0,0,1,22.94-6.16L960,83.45V0Z"
				/>
				<path
					fill={bgcolor === 'brown' ? '#452e14' : '#141945'}
					d="M632.91,308.87,879.14,78.6l-1.89-.47a25.21,25.21,0,0,0-23,5.76L601.94,311.68a12.62,12.62,0,0,0,5.4,21.63l115.38,28.77L675.19,405h28.19l51.17-47.85L638.69,330.4a12.63,12.63,0,0,1-5.78-21.53Z"
				/>
				<path
					fill={bgcolor === 'brown' ? '#452e14' : '#141945'}
					d="M540,332.16l-39.83-9.93a2.77,2.77,0,0,1-2-3.36v0a2.69,2.69,0,0,1,.81-1.35l37.56-34.07-25.15-6.27a2.71,2.71,0,0,1-2-2,2.74,2.74,0,0,1,.82-2.74l55.06-50a5.5,5.5,0,0,1,5-1.26l39.83,9.93a2.76,2.76,0,0,1,1.17,4.73l-28.5,25.85L608,268a2.77,2.77,0,0,1,2,3.36,2.86,2.86,0,0,1-.83,1.37L545,330.9A5.47,5.47,0,0,1,540,332.16Zm30.68-98.76-40.13,36.4,25.15,6.27a2.78,2.78,0,0,1,1.17,4.74l-37.56,34.07L539.68,320l49.19-44.62-25.15-6.27a2.77,2.77,0,0,1-2-3.36,2.89,2.89,0,0,1,.83-1.38l28.5-25.85Z"
				/>
				<path
					fill={bgcolor === 'brown' ? '#4c3418' : '#181d4c'}
					d="M851,.74a3.72,3.72,0,0,0,1.59,6.39l34,8.47L820.12,75.71l-27.46-6.85L843.35,23a3.73,3.73,0,0,0-1.58-6.39l-34-8.46,9-8.11H793.19L780.37,11.61A3.72,3.72,0,0,0,782,18l34,8.47L765.3,72.38a3.73,3.73,0,0,0,1.59,6.38l53.8,13.42a7.43,7.43,0,0,0,6.78-1.71L914,12.1a3.74,3.74,0,0,0-1.59-6.39L889.48,0h-37.7Z"
				/>
			</svg>
		</div>
	)
}
