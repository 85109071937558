import { CMSWebsiteBlock } from 'cms-types'
import { cmsblocks } from './cmsblocks'
import { createContext } from 'react'
import { styler } from 'util/styler/Styler'

const styles = styler('blocks')

export const BlockContext = createContext<{
	block: CMSWebsiteBlock
	container: 'none' | 'small'
	setVisibleAnchor: (anchor: string) => void
}>({
	block: null,
	container: null,
	setVisibleAnchor: (anchor: string) => {}
})

export const Blocks: React.FC<{
	blocks: Array<CMSWebsiteBlock>
	container?: 'none' | 'small'
	className?: string
	setVisibleAnchor?: (anchor: string) => void
}> = ({blocks, container, className, setVisibleAnchor}) => {
	if (!blocks || blocks.length === 0) return null
	const nopadbot = blocks[blocks.length - 1]?.type === 'featuredinspiration'

	return (
		<div className={styles.with(className).mod({nopadbot})()}>
			{blocks.map((block, i) => (
				<BlockContext.Provider
					value={{
						block: block,
						container: container,
						setVisibleAnchor: setVisibleAnchor
					}}
					key={i}
				>
					<CMSBlock block={block} />
				</BlockContext.Provider>
			))}
		</div>
	)
}

export const CMSBlock: React.FC<{block: CMSWebsiteBlock}> = ({block}) => {
	const Component: React.FC<CMSWebsiteBlock> = cmsblocks[block.type]
	if (!Component) {
		return null
	}
	return <Component {...block} />
}
