import {CMSRoadmapBlock, CMSWysiwyg} from 'cms-types'

import {Block} from 'blocks/Block'
import {Button} from 'layout/Button'
import {Image} from 'layout/Image'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Roadmap.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Roadmap: React.FC<CMSRoadmapBlock> = block => {
	const {title, text, image, items, button} = block
	if (!items || items.length === 0) return null

	return (
		<Block block={block} className={styles.roadmap()}>
			<div className={styles.roadmap.row()}>
				<div className={styles.roadmap.row.content()}>
					<Title.H2 className={styles.roadmap.row.content.title()}>
						{title}
					</Title.H2>
					<Wysiwyg className={styles.roadmap.row.content.text()}>
						{text}
					</Wysiwyg>
					{image?.src && (
						<div className={styles.roadmap.row.content.image()}>
							<Image {...image} layout="responsive" sizes="336px" />
							{image?.meta?.copyright && (
								<p className={styles.roadmap.row.content.image.copyright()}>
									{'© ' + image.meta.copyright}
								</p>
							)}
						</div>
					)}
				</div>
				<div className={styles.roadmap.row.items()}>
					{items.map((item, i) => (
						<RoadmapItem {...item} key={i} index={i} />
					))}
				</div>
			</div>
			{button?.url && (
				<div className={styles.roadmap.button()}>
					<Button to={button.url} iconafter="chevron_right" mod="large">
						{button.description || button.title}
					</Button>
				</div>
			)}
		</Block>
	)
}

const RoadmapItem: React.FC<{
	index: number
	title: string
	text?: CMSWysiwyg
}> = ({index, title, text}) => {
	if (!title && !text) return null

	return (
		<div className={styles.item()}>
			<div className={styles.item.number()}>{index + 1 + '.'}</div>
			<div className={styles.item.content()}>
				<Title.H3 className={styles.item.content.title()}>{title}</Title.H3>
				{text && (
					<Wysiwyg className={styles.item.content.text()}>{text}</Wysiwyg>
				)}
			</div>
		</div>
	)
}
