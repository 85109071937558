import {Block} from 'blocks/Block'
import {CMSCtablockBlock} from 'cms-types'
import {Cta} from 'layout/cta/Cta'
import css from './Ctablock.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Ctablock: React.FC<CMSCtablockBlock> = block => {
	const {title, text, button, bgcolor, image} = block
	if (!title && !text && !button?.url) return null

	return (
		<Block block={block} className={styles.ctablock()}>
			<Cta
				title={title}
				text={text}
				button={button}
				bgcolor={bgcolor}
				image={image}
			/>
		</Block>
	)
}
