import {Accordion} from './accordion/Accordion'
import {Anchor} from './anchor/Anchor'
import {Asterisk} from './asterisk/Asterisk'
import {Calculatorblock} from './calculator/Calculatorblock'
import {Chart} from './chart/Chart'
import {Cookiedeclartion} from './cookiedeclaration/Cookiedeclartion'
import {Ctablock} from './ctablock/Ctablock'
import {Divider} from './divider/Divider'
import {FeaturedInspiration} from './featuredinspiration/FeaturedInspiration'
import {FeaturedTips} from './featuredtips/FeaturedTips'
import {ImageCollection} from './imagecollection/ImageCollection'
import {ImageText} from './imagetext/ImageText'
import {Imageblock} from './imageblock/Imageblock'
import {Matomo} from './matomo/Matomo'
import {Quote} from './quote/Quote'
import {Roadmap} from './roadmap/Roadmap'
import {Table} from './table/Table'
import {Text} from './text/Text'
import {Video} from './video/Video'

export const cmsblocks = {
	accordion: Accordion,
	anchor: Anchor,
	asterisk: Asterisk,
	calculator: Calculatorblock,
	chart: Chart,
	cookiedeclaration: Cookiedeclartion,
	ctablock: Ctablock,
	divider: Divider,
	featuredinspiration: FeaturedInspiration,
	featuredtips: FeaturedTips,
	imageblock: Imageblock,
	imagecollection: ImageCollection,
	imagetext: ImageText,
	matomo: Matomo,
	quote: Quote,
	roadmap: Roadmap,
	table: Table,
	text: Text,
	video: Video
}
