import { Block } from 'blocks/Block'
import { CMSChartBlock } from 'cms-types'
import { Loader } from 'layout/Loader'
import { Title } from 'layout/Title'
import { Trusted } from 'layout/Trusted'
import { Wysiwyg } from 'layout/Wysiwyg'
import { useEffect, useState } from 'react'
import { fromModule } from 'util/styler/Styler'
import css from './Chart.module.scss'


const styles = fromModule(css)

export const Chart: React.FC<CMSChartBlock> = block => {
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		// Append scripts in document head
		const loadScripts = []
		const foundLoadScripts = block.script.match(/<script[^>]*src=[^>]*>/g)
		if (foundLoadScripts && foundLoadScripts.length) {
			const knownSources = []
			for (let i = 0; i < document.scripts.length; i++)
				knownSources.push(document.scripts.item(i).getAttribute('src'))
			foundLoadScripts.forEach(ls => {
				const scriptSrc = ls.replace(/(^.*src=['"])|(['"].+?$)/g, '')
				if (knownSources.indexOf(scriptSrc) < 0) {
					const newScript = document.createElement('script')
					newScript.src = scriptSrc
					newScript.type = 'text/javascript'
					newScript.async = false
					document.head.appendChild(newScript)
					loadScripts.push(newScript)
				}
			})
		}

		// Run code between <script> tags
		const addWait = loadScripts.length ? 3000 : 0
		let runScripts = ''
		const foundRunScripts = block.script.match(/<script>[\S\s]+?<\/script>/g)
		if (foundRunScripts && foundRunScripts.length)
			foundRunScripts.forEach(rs => {
				runScripts += rs.replace('<script>', '').replace('</script>', '')
			})

		let tries = 0
		const runCode = () => {
			tries++
			if (!runScripts.length || tries > 3) return
			setTimeout(() => {
				try {
					eval(runScripts)
				} catch (e) {
					console.log('chartblock error: ' + e.message)
					runCode()
				}
			}, addWait)
		}

		runCode()

		setTimeout(() => {
			setLoading(false)
		}, 1500 + addWait)

		// Remove appended scripts in document head
		return () => {
			loadScripts.forEach(ls => {
				document.head.removeChild(ls)
			})
		}
	}, [])

	// Create initial HTML with div and svg
	let fallBackContainer = block.svg ? block.svg.replace(/\s/g, ' ') : ''
	const foundDivs = block.script.match(/<div[^>]*id=[^>]*>/g)
	if (foundDivs && foundDivs.length)
		foundDivs.reverse().forEach(div => {
			fallBackContainer = div.replace('/>', '>') + fallBackContainer + '</div>'
		})
	if (block.width) {
		const widthString = `  style="width: 100%; max-width: ${block.width}px; margin: 0 auto;"`
		const fullWidthString = `  style="width: 100%; margin: 0 auto;"`
		fallBackContainer = fallBackContainer
			.replace('<svg', `<svg${widthString}`)
			.replace('<div ', `<div${fullWidthString} `)
	}

	return (
		<Block block={block} className={styles.chart()}>
			{block.title && (
				<Title.H5 className={styles.title()}>
					<Trusted>{block.title}</Trusted>
				</Title.H5>
			)}
			<div
				className={styles.iframe.mod({loading})()}
				style={{maxWidth: block?.width || 700 + 'px'}}
				dangerouslySetInnerHTML={{__html: fallBackContainer}}
			></div>
			{loading && <Loader mod="chart" />}
			{block.caption && (
				<Wysiwyg className={styles.caption()}>{block.caption}</Wysiwyg>
			)}
		</Block>
	)
}
