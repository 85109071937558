import {CMSImage} from 'cms-types'
import {Image} from 'layout/Image'
import {Link} from 'layout/Link'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import css from './InspirationCard.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export type InspirationCardType = {
	url: string
	title: string
	date?: string
	image: CMSImage
	linklabel?: string
	mod?: 'featured'
	tabIndex?: number | undefined
	ariaHidden?: boolean
}

export const InspirationCard: React.FC<InspirationCardType> = ({
	url,
	title,
	image,
	linklabel,
	mod,
	tabIndex,
	ariaHidden
}) => {
	const t = useTranslation().inspiration
	if (!url && !title) return null

	return (
		<Link
			to={url}
			tabIndex={tabIndex}
			aria-hidden={ariaHidden}
			className={styles.inspirationcard.mod(mod)()}
		>
			<div className={styles.inspirationcard.image()}>
				{image?.src && (
					<div className={styles.inspirationcard.image.bg()}>
						<Image {...image} layout="fill" sizes="610px" />
					</div>
				)}
			</div>
			<div className={styles.inspirationcard.content()}>
				{title && (
					<Title.H4 as="h3" className={styles.inspirationcard.content.title()}>
						{title}
					</Title.H4>
				)}
				<LinkStyled
					as="span"
					iconafter="chevron_right"
					className={styles.inspirationcard.content.link()}
					mod="inherit"
				>
					{linklabel || t.linklabel}
				</LinkStyled>
			</div>
		</Link>
	)
}
