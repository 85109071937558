import { CMSWebsiteBlock } from 'cms-types'
import { Container } from 'layout/Container'
import {
	Fragment,
	HTMLAttributes,
	useContext,
	useEffect,
	useRef
} from 'react'
import { slugify } from 'util/slugify'
import { styler } from 'util/styler/Styler'
import { BlockContext } from './Blocks'


const styles = styler('block')

export const Block: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		block: CMSWebsiteBlock
	}
> = ({children, block, ...props}) => {
	const {container, setVisibleAnchor} = useContext(BlockContext)
	const ContainerTag = container === 'none' ? Fragment : Container
	const containerProps: {mod?: 'small'} =
		container === 'small' ? {mod: 'small'} : null

	const anchorRef = useRef<HTMLDivElement>(null)

	const scrollCallback = (entries: IntersectionObserverEntry[]) => {
		const entry = entries[0]
		if (entry.isIntersecting && entry.target.className.includes('anchor')) {
			setVisibleAnchor(entry.target.id)
		}
	}

	useEffect(() => {
		if (!('IntersectionObserver' in window)) return
		const {current} = anchorRef
		const observer = new IntersectionObserver(scrollCallback, {
			root: null,
			rootMargin: '0px 0px -80% 0px',
			threshold: 1
		})
		observer.observe(current)
		return () => {
			observer.disconnect()
		}
	}, [anchorRef.current])

	return (
		<div
			id={'title' in block ? slugify(block.title) : undefined}
			ref={anchorRef}
			{...props}
			className={styles.with(block.type).with(props.className)()}
		>
			<ContainerTag {...containerProps}>{children}</ContainerTag>
		</div>
	)
}
