import {Block} from 'blocks/Block'
import {CMSCalculatorBlock} from 'cms-types'
import {CMSCalculatorBlockExtra} from './calculator.data'
import {ComponentType} from 'react'
import {calculators} from 'calculators'
import css from './Calculatorblock.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Calculatorblock: React.FC<
	CMSCalculatorBlock & CMSCalculatorBlockExtra
> = block => {
	const {config} = block
	if (!config) return null

	const Component: ComponentType<{config}> = calculators[config.channel]

	return (
		<Block block={block}>
			<div className={styles.calculatorblock()}>
				<Component config={config} />
			</div>
		</Block>
	)
}
