import {useApp} from 'AppContext'
import {Block} from 'blocks/Block'
import {CMSImagetextBlock} from 'cms-types'
import {Image} from 'layout/Image'
import {Wysiwyg} from 'layout/Wysiwyg'
import {Zoom} from 'layout/Zoom'
import {useMediaQuery} from 'util/mediaquery'
import {fromModule} from 'util/styler/Styler'
import css from './ImageText.module.scss'

const styles = fromModule(css)

export const ImageText: React.FC<CMSImagetextBlock> = block => {
	const {node} = useApp()
	const isDesktop = useMediaQuery('(min-width: 1200px)')
	const {image, image_width, caption, text} = block

	return (
		<Block block={block} className={styles.imagetext.mod(node?.channel)()}>
			<div className={styles.imagetext.row()}>
				<div
					className={styles.imagetext.row.image()}
					style={
						image_width && image_width > 0
							? isDesktop
								? {
										maxWidth: image_width + 'px',
										marginLeft: -image_width - 60 + 'px'
								  }
								: {maxWidth: image_width + 'px'}
							: {}
					}
				>
					{image?.src && (
						<Zoom image={image}>
							<Image {...image} layout="responsive" sizes="415px" />
						</Zoom>
					)}
					{image?.meta?.copyright && (
						<p className={styles.imagetext.row.image.copyright()}>
							{'© ' + image.meta.copyright}
						</p>
					)}
					{caption && (
						<Wysiwyg className={styles.imagetext.row.image.caption()}>
							{caption}
						</Wysiwyg>
					)}
				</div>
				<div className={styles.imagetext.row.content()}>
					{text && <Wysiwyg>{text}</Wysiwyg>}
				</div>
			</div>
		</Block>
	)
}
