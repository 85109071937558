import {CMSImage} from 'cms-types'
import React, {Fragment, useRef, useState} from 'react'
import {fromModule} from 'util/styler/Styler'
import css from './Zoom.module.scss'

const styles = fromModule(css)

export const Zoom: React.FC<{image: CMSImage}> = ({image, children}) => {
	const [zoomed, setZoomed] = useState<boolean>(false)
	const [zooming, setZooming] = useState<boolean>(false)
	const [loaded, setLoaded] = useState<boolean>(false)
	const [pos, setPos] = useState<{x: number; y: number}>({x: 0, y: 0})
	const startRef = useRef(null)
	const imgRef = useRef(null)

	const preloadImage = () => {
		if (loaded) {
			openZoom()
			return
		}
		setZooming(true)
		const preLoad = new Image()
		preLoad.onload = () => {
			setLoaded(true)
			openZoom()
		}
		preLoad.src = image.src
	}

	const openZoom = () => {
		if (zoomed || zooming) return
		setZooming(true)
		const startRect = startRef.current.getBoundingClientRect()
		const hCenter = startRect.left + startRect.width / 2
		const vCenter = startRect.bottom - startRect.height / 2
		const trX = (window.innerWidth / 2 - hCenter) * -1
		const trY = (window.innerHeight / 2 - vCenter) * -1
		imgRef.current.style.transform = `translate(${trX}px, ${trY}px)`
		setPos({x: trX, y: trY})
		requestAnimationFrame(() => {
			setZoomed(true)
			imgRef.current.style.transform = `translate(0,0)`
		})
		document.body.style.overflow = 'hidden'
	}

	const closeZoom = () => {
		if (!zoomed && !zooming) return
		setZoomed(false)
		imgRef.current.style.transform = `translate(${pos.x}px, ${pos.y}px)`
		setTimeout(() => {
			setZooming(false)
			imgRef.current.style.transform = `translate(0,0)`
		}, 300)
		document.body.style.overflow = ''
	}

	return (
		<Fragment>
			<div ref={startRef} className={styles.zoom()} onClick={preloadImage}>
				{children}
			</div>
			<div
				className={styles.overlay.mod({zooming, zoomed})()}
				onClick={closeZoom}
			>
				{!loaded && <div className={styles.loader()} />}
				<div ref={imgRef} className={styles.image.container()}>
					<div
						className={styles.image.mod({zooming, zoomed, loading: !loaded})()}
						style={{
							backgroundImage: `url("${image.src}")`,
							width: `${image.width}px`,
							height: `${image.height}px`
						}}
					></div>
				</div>
			</div>
		</Fragment>
	)
}
