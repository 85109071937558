import {useApp} from 'AppContext'
import {CMSImage, CMSWysiwyg} from 'cms-types'
import {format} from 'date-fns'
import {Icon} from 'layout/Icon'
import {Image} from 'layout/Image'
import {Link} from 'layout/Link'
import {TipLabel, TipRating} from 'layout/tip/Tip'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useTranslation} from 'locale/LocaleContext'
import {fromModule} from 'util/styler/Styler'
import css from './TipCard.module.scss'

const styles = fromModule(css)

export type TipsCardTypeData =
	| 'purchase'
	| 'behaviour'
	| 'environment'
	| 'premiums'
	| 'info'
export type TipsCardImpact = '1' | '2' | '3' | '4'
export type TipsCardInvestment = '0' | '1' | '2' | '3' | '4'

export type TipCardType = {
	id: number
	url: string
	title: string
	type: TipsCardTypeData
	impact: TipsCardImpact
	investment: TipsCardInvestment
	image: CMSImage
	text: CMSWysiwyg
	category?: number
	subcategory?: number
	mod?: 'featured'
	tabIndex?: number | undefined
	ariaHidden?: boolean
}

export const TipCard: React.FC<TipCardType> = ({
	id,
	url,
	title,
	type,
	impact,
	investment,
	image,
	text,
	mod,
	tabIndex,
	ariaHidden
}) => {
	const app = useApp()
	const {node} = app
	const t = useTranslation()
	if (!url && !title) return null

	const toggleFavorite = () => {
		const newFavorites = app.user.favorites.slice()
		const tipIndex = newFavorites.indexOf(id)
		if (tipIndex < 0) newFavorites.push(id)
		else newFavorites.splice(tipIndex, 1)
		app.updateFavorites(newFavorites)
	}

	const toggleDone = () => {
		const newTips = app.user.tips.slice()
		const tipIndex = newTips.map(e => e.id).indexOf(id)
		if (tipIndex < 0) newTips.push({id, date: format(new Date(), 'yyyy-MM-dd')})
		else newTips.splice(tipIndex, 1)
		app.updateTips(newTips)
	}

	const isFavorite = app.user.favorites.indexOf(id) >= 0
	const isDone = app.user.tips.map(e => e.id).indexOf(id) >= 0

	return (
		<div
			tabIndex={tabIndex}
			aria-hidden={ariaHidden}
			className={styles.tipcard.mod(mod).mod(node?.channel)()}
		>
			<Link to={url} tabIndex={-1} className={styles.tipcard.image()}>
				{image?.src && (
					<div className={styles.tipcard.image.bg()}>
						<Image {...image} layout="fill" sizes="610px" />
					</div>
				)}
			</Link>
			<div className={styles.tipcard.options()}>
				<button
					onClick={toggleFavorite}
					tabIndex={tabIndex}
					className={styles.tipcard.options.link.is({
						active: isFavorite
					})()}
					aria-label={
						isFavorite ? t.tips.favorite.delete : t.tips.favorite.save
					}
				>
					<Icon icon={isFavorite ? 'heart_solid' : 'heart_regular'} />
				</button>
				{type !== 'info' && type !== 'premiums' && (
					<button
						onClick={toggleDone}
						tabIndex={tabIndex}
						className={styles.tipcard.options.link.is({active: isDone})()}
						aria-label={(isDone
							? t.tips.status.done
							: t.tips.status.todo
						).trim()}
					>
						<Icon icon={isDone ? 'check_circle_solid' : 'check_light'} />
					</button>
				)}
			</div>
			<Link to={url} tabIndex={-1} className={styles.tipcard.content()}>
				<div className={styles.tipcard.content.top()}>
					<div className={styles.tipcard.content.top.label()}>
						<TipLabel type={type} />
					</div>
					{title && (
						<Title.H4 className={styles.tipcard.content.top.title()}>
							{title}
						</Title.H4>
					)}
					{text && (
						<Wysiwyg className={styles.tipcard.content.top.text()}>
							{text.slice(0, 125)}
						</Wysiwyg>
					)}
				</div>
				{type !== 'info' && type !== 'premiums' && (
					<div className={styles.tipcard.content.rating()}>
						<div className={styles.tipcard.content.rating.item()}>
							<TipRating type="impact" number={Number(impact)} />
						</div>
						<div className={styles.tipcard.content.rating.item()}>
							<TipRating type="investment" number={Number(investment)} />
						</div>
					</div>
				)}
			</Link>
		</div>
	)
}
