import {Block} from 'blocks/Block'
import {CMSTableBlock} from 'cms-types'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Table.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'

const styles = fromModule(css)

export const Table: React.FC<CMSTableBlock> = block => {
	const {node} = useApp()
	const {title, fullwidth, table, caption} = block
	if (!table) return null

	return (
		<Block
			block={block}
			className={styles.table.mod({fullwidth}).mod(node?.channel)()}
		>
			{title && <Title.H3 className={styles.table.title()}>{title}</Title.H3>}
			<Wysiwyg className={styles.table.table()}>{table}</Wysiwyg>
			{caption && (
				<Wysiwyg className={styles.table.caption()}>{caption}</Wysiwyg>
			)}
		</Block>
	)
}
