import {Block} from 'blocks/Block'
import {CMSMatomoBlock} from 'cms-types'
import {useApp} from 'AppContext'

export const Matomo: React.FC<CMSMatomoBlock> = block => {
	const {lang} = useApp()

	return (
		<Block block={block}>
			<iframe
				width="100%"
				height={200}
				src={`https://analytics.klimaat.be/index.php?module=CoreAdminHome&action=optOut&language=${lang}&fontFamily=Arial,+Helvetica,+sans-serif`}
			/>
		</Block>
	)
}
