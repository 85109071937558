import {Block} from 'blocks/Block'
import {CMSImage, CMSImagecollectionBlock} from 'cms-types'
import {Image} from 'layout/Image'
import {Zoom} from 'layout/Zoom'
import {fromModule} from 'util/styler/Styler'
import css from './ImageCollection.module.scss'

const styles = fromModule(css)

export const ImageCollection: React.FC<CMSImagecollectionBlock> = block => {
	const {images} = block
	if (!images || images.length === 0) return null
	return (
		<Block block={block} className={styles.imagecollection()}>
			{switchImages(images)}
		</Block>
	)
}

const switchImages = images => {
	if (images.length === 5) {
		return (
			<>
				<ImageCollectionArray images={images.slice(0, 2)} />
				<ImageCollectionArray images={images.slice(2, images.length)} />
			</>
		)
	}

	if (images.length % 3 === 0)
		return (
			<>
				{chunkArray(images, 3).map((chunkedArray, i) => (
					<ImageCollectionArray key={i} images={chunkedArray} />
				))}
			</>
		)

	if (images.length % 2 === 0)
		return chunkArray(images, 2).map((chunkedArray, i) => (
			<ImageCollectionArray key={i} images={chunkedArray} />
		))

	return <ImageCollectionArray images={images} />
}

const ImageCollectionArray: React.FC<{images: CMSImage[]}> = ({images}) => {
	return (
		<div className={styles.array()}>
			<div className={styles.array.row.mod(`length${images.length}`)()}>
				{images.map((image, i) => (
					<div className={styles.array.row.image()} key={i}>
						<Zoom image={image}>
							<Image {...image} layout="fill" sizes="960px" />
						</Zoom>
						{image?.meta?.copyright && (
							<p className={styles.array.row.image.copyright()}>
								{'© ' + image.meta.copyright}
							</p>
						)}
					</div>
				))}
			</div>
		</div>
	)
}

function chunkArray(myArray, chunk_size) {
	var index = 0
	var arrayLength = myArray.length
	var tempArray = []

	for (index = 0; index < arrayLength; index += chunk_size) {
		let myChunk = myArray.slice(index, index + chunk_size)
		tempArray.push(myChunk)
	}

	return tempArray
}
