import {Block} from 'blocks/Block'
import {CMSAsteriskBlock} from 'cms-types'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Asterisk.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Asterisk: React.FC<CMSAsteriskBlock> = block => {
	const {asterisk} = block
	if (!asterisk) return null

	return (
		<Block block={block} className={styles.asterisk()}>
			<Wysiwyg className={styles.asterisk.content()}>{asterisk}</Wysiwyg>
		</Block>
	)
}
