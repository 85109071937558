import {Block} from 'blocks/Block'
import {CMSTextBlock} from 'cms-types'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Text.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Text: React.FC<CMSTextBlock> = block => {
	const {text} = block

	return (
		<Block block={block} className={styles.text()}>
			<Wysiwyg className={styles.text.content()}>{text}</Wysiwyg>
		</Block>
	)
}
