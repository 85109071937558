import {Block} from 'blocks/Block'
import {CMSCookiedeclarationBlock} from 'cms-types'
import Script from 'next/script'
import {useApp} from 'AppContext'

export const Cookiedeclartion: React.FC<CMSCookiedeclarationBlock> = block => {
	const {lang} = useApp()
	const {cookiebot_id} = block
	if (!cookiebot_id) return null

	return (
		<Block block={block}>
			<Script
				data-culture={lang.toUpperCase()}
				src={`https://consent.cookiebot.com/${cookiebot_id}/cd.js`}
				async
			/>
			<div id="CookieDeclaration" />
		</Block>
	)
}
