import { Icon } from 'layout/Icon'
import React from 'react'
import css from './CarouselControls.module.scss'
import { fromModule } from 'util/styler/Styler'
import { useCarousel } from '@codeurs/carousel'

const styles = fromModule(css)

type CarouselType = ReturnType<typeof useCarousel>

type CarouselControlsType = {
	carousel: CarouselType
	className?: string
	mod?: 'absolute'
}

export const CarouselPrev: React.FC<CarouselControlsType> = ({
	carousel,
	className,
	mod
}) => {
	if (carousel.total < 2) return null
	return (
		<button
			onClick={() => carousel.goPrevious()}
			disabled={!carousel.hasPrevious()}
			aria-hidden={!carousel.hasPrevious()}
			aria-label="Carousel previous button"
			className={styles.prev.with(className).mod(mod)()}
		>
			<Icon icon="chevron_left" />
		</button>
	)
}

export const CarouselNext: React.FC<CarouselControlsType> = ({
	carousel,
	className,
	mod
}) => {
	if (carousel.total < 2) return null
	return (
		<button
			onClick={() => carousel.goNext()}
			disabled={!carousel.hasNext()}
			aria-hidden={!carousel.hasNext()}
			aria-label="Carousel next button"
			className={styles.next.with(className).mod(mod)()}
		>
			<Icon icon="chevron_right" />
		</button>
	)
}

export const CarouselScroll: React.FC<{
	carousel: CarouselType
	mod?: 'dark'
}> = ({carousel, mod}) => {
	if (!carousel.hasNext() && !carousel.hasPrevious()) return null
	return (
		<div className={styles.scroll.mod(mod)()}>
			{Array.from(Array(carousel.total)).map((_, i) => (
				<div
					key={i}
					className={styles.scroll.dot.mod(mod).is({
						active: carousel.current === i
					})()}
				/>
			))}
		</div>
	)
}
