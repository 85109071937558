import {Block} from 'blocks/Block'
import {CMSQuoteBlock} from 'cms-types'
import {Image} from 'layout/Image'
import {Trusted} from 'layout/Trusted'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Quote.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Quote: React.FC<CMSQuoteBlock> = block => {
	const {image, quote, text} = block
	if (!quote) return null

	return (
		<Block block={block} className={styles.quote()}>
			<div className={styles.quote.row()}>
				{image?.src && (
					<div className={styles.quote.row.image()}>
						<Image {...image} layout="fill" sizes="67px" />
					</div>
				)}
				<div className={styles.quote.row.content()}>
					<blockquote className={styles.quote.row.content.quote()}>
						<Trusted>{quote}</Trusted>
					</blockquote>
					{text && (
						<Wysiwyg className={styles.quote.row.content.text()}>
							{text}
						</Wysiwyg>
					)}
				</div>
			</div>
		</Block>
	)
}
