import dynamic from 'next/dynamic'

const importOptions = {ssr: false}

const {
	DakIsolatie,
	DiepvriezersVergelijken,
	DiepvriezerOntdooien,
	DiepvriezerUitschakelen,
	DroogkastenVergelijken,
	DroogkastBuitenDrogen,
	GroeneStroomOverschakelen,
	KoelkastOntdooien,
	KoelkastUitschakelen,
	KoelkastenVergelijken,
	KookplatenVergelijken,
	Muurisolatie,
	OvensVergelijken,
	VaatwasserVullen,
	VaatwassersVergelijken,
	VenstersVervangen,
	VerlichtingVervangen,
	VerwarmingVergelijken,
	VerwarmingVerlagen,
	WagenFietsen,
	WarmWaterVergelijken,
	WagenInruilen,
	WagenKopen,
	WagenRijden,
	WasmachineEcoGebruiken,
	WasmachineVerlagen,
	WasmachineVullen,
	WasmachinesVergelijken
} = {
	DakIsolatie: dynamic(
		() => import('calculators/dak/dakisolatie/DakIsolatie'),
		importOptions
	),
	DiepvriezersVergelijken: dynamic(
		() => import('calculators/diepvriezer/diepvriezers_vergelijken/DiepvriezersVergelijken'),
		importOptions
	),
	DiepvriezerOntdooien: dynamic(
		() => import('calculators/diepvriezer/diepvriezer_ontdooien/DiepvriezerOntdooien'),
		importOptions
	),
	DiepvriezerUitschakelen: dynamic(
		() => import('calculators/diepvriezer/diepvriezer_uitschakelen/DiepvriezerUitschakelen'),
		importOptions
	),
	DroogkastenVergelijken: dynamic(
		() => import('calculators/droogkast/droogkasten_vergelijken/DroogkastenVergelijken'),
		importOptions
	),
	DroogkastBuitenDrogen: dynamic(
		() => import('calculators/droogkast/droogkast_buiten_drogen/DroogkastBuitenDrogen'),
		importOptions
	),
	GroeneStroomOverschakelen: dynamic(
		() => import('calculators/groenestroom/groene_stroom_overschakelen/GroeneStroomOverschakelen'),
		importOptions
	),
	KoelkastOntdooien: dynamic(
		() => import('calculators/koelkast/koelkast_ontdooien/KoelkastOntdooien'),
		importOptions
	),
	KoelkastUitschakelen: dynamic(
		() =>
			import('calculators/koelkast/koelkast_uitschakelen/KoelkastUitschakelen'),
		importOptions
	),
	KoelkastenVergelijken: dynamic(
		() => import('calculators/koelkast/koelkasten_vergelijken/KoelkastenVergelijken'),
		importOptions
	),
	KookplatenVergelijken: dynamic(
		() => import('calculators/kookplaten/kookplaten_vergelijken/KookplatenVergelijken'),
		importOptions
	),
	Muurisolatie: dynamic(
		() => import('calculators/muren/muurisolatie/Muurisolatie'),
		importOptions
	),
	OvensVergelijken: dynamic(
		() => import('calculators/oven/ovens_vergelijken/OvensVergelijken'),
		importOptions
	),
	VaatwasserVullen: dynamic(
		() => import('calculators/vaatwasser/vaatwasser_vullen/VaatwasserVullen'),
		importOptions
	),
	VaatwassersVergelijken: dynamic(
		() => import('calculators/vaatwasser/vaatwassers_vergelijken/VaatwassersVergelijken'),
		importOptions
	),
	VenstersVervangen: dynamic(
		() => import('calculators/vensters/venstersvervangen/VensterVervangen'),
		importOptions
	),
	VerlichtingVervangen: dynamic(
		() => import('calculators/verlichting/verlichting_vervangen/VerlichtingVervangen'),
		importOptions
	),
	VerwarmingVergelijken: dynamic(
		() => import('calculators/verwarming/verwarming_vergelijken/VerwarmingVergelijken'),
		importOptions
	),
	VerwarmingVerlagen: dynamic(
		() =>
			import('calculators/verwarming/verwarming_verlagen/VerwarmingVerlagen'),
		importOptions
	),
	WagenFietsen: dynamic(
		() => import('calculators/wagen/wagen_fietsen/WagenFietsen'),
		importOptions
	),
	WagenInruilen: dynamic(
		() => import('calculators/wagen/wagen_inruilen/WagenInruilen'),
		importOptions
	),
	WagenKopen: dynamic(
		() => import('calculators/wagen/wagen_kopen/WagenKopen'),
		importOptions
	),
	WagenRijden: dynamic(
		() => import('calculators/wagen/wagen_rijden/WagenRijden'),
		importOptions
	),
	WarmWaterVergelijken: dynamic(
		() =>
			import('calculators/warmwater/warmwatervergelijken/WarmWaterVergelijken'),
		importOptions
	),
	WasmachineEcoGebruiken: dynamic(
		() => import('calculators/wasmachine/wasmachine_eco_gebruiken/WasmachineEcoGebruiken'),
		importOptions
	),
	WasmachineVerlagen: dynamic(
		() =>
			import('calculators/wasmachine/wasmachine_verlagen/WasmachineVerlagen'),
		importOptions
	),
	WasmachineVullen: dynamic(
		() => import('calculators/wasmachine/wasmachine_vullen/WasmachineVullen'),
		importOptions
	),
	WasmachinesVergelijken: dynamic(
		() => import('calculators/wasmachine/wasmachines_vergelijken/WasmachinesVergelijken'),
		importOptions
	)
}

export const calculators = {
	venstersvervangen: VenstersVervangen,
	dakisolatie: DakIsolatie,
	muurisolatie: Muurisolatie,
	diepvriezerontdooien: DiepvriezerOntdooien,
	diepvriezersvergelijken: DiepvriezersVergelijken,
	diepvriezeruitschakelen: DiepvriezerUitschakelen,
	droogkastbuitendrogen: DroogkastBuitenDrogen,
	droogkastenvergelijken: DroogkastenVergelijken,
	groenestroomoverschakelen: GroeneStroomOverschakelen,
	koelkastenvergelijken: KoelkastenVergelijken,
	koelkastontdooien: KoelkastOntdooien,
	koelkastuitschakelen: KoelkastUitschakelen,
	kookplatenvergelijken: KookplatenVergelijken,
	ovensvergelijken: OvensVergelijken,
	vaatwassersvergelijken: VaatwassersVergelijken,
	vaatwasservullen: VaatwasserVullen,
	verlichtingvervangen: VerlichtingVervangen,
	verwarmingvergelijken: VerwarmingVergelijken,
	verwarmingverlagen: VerwarmingVerlagen,
	wagenfietsen: WagenFietsen,
	wageninruilen: WagenInruilen,
	wagenkopen: WagenKopen,
	wagenrijden: WagenRijden,
	warmwatervergelijken: WarmWaterVergelijken,
	wasmachineecogebruiken: WasmachineEcoGebruiken,
	wasmachinesvergelijken: WasmachinesVergelijken,
	wasmachineverlagen: WasmachineVerlagen,
	wasmachinevullen: WasmachineVullen
}

export type CalculatorKey = keyof typeof calculators
