import {Carousel, useCarousel} from '@codeurs/carousel'
import {
	CarouselNext,
	CarouselPrev,
	CarouselScroll
} from 'layout/carousel/CarouselControls'

import {Block} from 'blocks/Block'
import {CMSFeaturedtipsBlock} from 'cms-types'
import {CMSFeaturedtipsBlockExtra} from './featuredtips.data'
import {LinkStyled} from 'layout/LinkStyled'
import {TipCard} from 'layout/cards/TipCard'
import {Title} from 'layout/Title'
import css from './FeaturedTips.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useEffect} from 'react'
import {useMediaQuery} from 'util/mediaquery'

const styles = fromModule(css)

export const FeaturedTips: React.FC<
	CMSFeaturedtipsBlock & CMSFeaturedtipsBlockExtra
> = block => {
	const {node} = useApp()
	const carousel = useCarousel()
	const {title, cards, link} = block
	const isIpadLand = useMediaQuery('(min-width: 1024px)')

	useEffect(() => {
		if (!cards?.length) return null
	}, [cards.length])

	return (
		<Block block={block} className={styles.featuredtips.mod(node?.channel)()}>
			{title && (
				<Title.H2 className={styles.featuredtips.title()}>{title}</Title.H2>
			)}
			<div className={styles.featuredtips.carousel()}>
				<CarouselPrev carousel={carousel} mod="absolute" />
				<div className={styles.featuredtips.carousel.items()}>
					<Carousel
						{...carousel}
						full={
							node?.channel === 'product_detail' && isIpadLand ? false : true
						}
					>
						{cards.map((card, index) => (
							<div
								className={styles.featuredtips.carousel.items.item.is(
									carousel.getPosition(index)
								)()}
								key={index}
							>
								<TipCard
									{...card}
									mod="featured"
									tabIndex={!carousel.isActive(index) ? -1 : 0}
									ariaHidden={!carousel.isActive(index)}
								/>
							</div>
						))}
					</Carousel>
				</div>
				<CarouselNext carousel={carousel} mod="absolute" />
				<CarouselScroll carousel={carousel} />
			</div>
			{link?.url && (
				<LinkStyled
					to={link.url}
					iconafter="chevron_right"
					className={styles.featuredtips.link()}
				>
					{link.description || link.title}
				</LinkStyled>
			)}
		</Block>
	)
}
