import {CMSImage, CMSWysiwyg} from 'cms-types'

import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import React from 'react'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Hero.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type HeroType = {
	image?: CMSImage
	title: string
	text?: CMSWysiwyg
}

export const Hero: React.FC<HeroType> = ({image, title, text}) => {
	if (!image && !title) return null

	return (
		<div className={styles.hero()}>
			<Container>
				<HeroTitle title={title} />
				<HeroText text={text} />
			</Container>
			{image?.src && (
				<div className={styles.hero.imgwrapper()}>
					<span className={styles.hero.imgwrapper.gray()} />
					<span className={styles.hero.imgwrapper.purple()} />
					<div className={styles.hero.imgwrapper.img()}>
						<Image
							{...image}
							layout="fill"
							sizes="100vw"
							priority
							className={styles.hero.imgwrapper.img.bg()}
						/>
						{image?.meta?.copyright && (
							<p className={styles.hero.imgwrapper.img.copyright()}>
								{'© ' + image.meta.copyright}
							</p>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export const HeroTitle = ({title}) => {
	return <Title.H1 className={styles.title()}>{title}</Title.H1>
}
export const HeroText = ({text}) => {
	if (!text) return null
	return <Wysiwyg className={styles.text()}>{text}</Wysiwyg>
}
