import {Icon} from 'layout/Icon'
import React from 'react'
import {TipsCardTypeData} from 'layout/cards/TipCard'
import {Variant} from 'util/styler'
import css from './Tip.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const TipLabel: React.FC<{
	type: TipsCardTypeData
}> = ({type}) => {
	const t = useTranslation()
	if (!type) return null

	return (
		<div className={styles.label.mod(type)()}>
			<span className={styles.label.icon()}>
				<Icon icon={type} />
			</span>
			<span className={styles.label.label()}>{t.tips[type]}</span>
		</div>
	)
}

export const TipRating: React.FC<{
	type: 'impact' | 'investment'
	number?: number
	mod?: Variant<'row'>
}> = ({type, number, mod}) => {
	const t = useTranslation()

	return (
		<div className={styles.rating.mod(mod)()}>
			<p className={styles.rating.label()}>{t.tips[type]}</p>
			<TipRatingIcons type={type} number={number} />
		</div>
	)
}

export const TipRatingIcons: React.FC<{
	type: 'impact' | 'investment'
	number: number
}> = ({type, number}) => (
	<div className={styles.ratingicons.mod(type)()}>
		{[1, 2, 3, 4].map(i => (
			<span
				key={i}
				className={styles.ratingicons.icon.is({active: number >= i})()}
			>
				<Icon icon={type} />
			</span>
		))}
	</div>
)
