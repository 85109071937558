import {CMSAccordionBlock, CMSWysiwyg} from 'cms-types'

import AnimateHeight from 'react-animate-height'
import {Block} from 'blocks/Block'
import {Icon} from 'layout/Icon'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Accordion.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useState} from 'react'

const styles = fromModule(css)

export const Accordion: React.FC<CMSAccordionBlock> = block => {
	const {items} = block
	if (!items || items.length === 0) return null

	return (
		<Block block={block} className={styles.accordion()}>
			{items.map((item, i) => (
				<AccordionItem {...item} key={i} />
			))}
		</Block>
	)
}

const AccordionItem: React.FC<{
	title: string
	text: CMSWysiwyg
}> = ({title, text}) => {
	const [isOpen, setOpen] = useState<boolean>(false)
	if (!title && !text) return null

	return (
		<div className={styles.item.is({open: isOpen})()}>
			<a onClick={() => setOpen(!isOpen)} className={styles.item.titlerow()}>
				<Title.H3 className={styles.item.titlerow.title()}>{title}</Title.H3>
				<span className={styles.item.titlerow.icon()}>
					<Icon icon="chevron_down" />
				</span>
			</a>
			<AnimateHeight height={isOpen ? 'auto' : 0}>
				<Wysiwyg className={styles.item.content()}>{text}</Wysiwyg>
			</AnimateHeight>
		</div>
	)
}
